@import url('./assets/font2.css');
@import url('./assets/font1.css');

$bg-gradient: linear-gradient(180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.3) 100%);

* {
    box-sizing: border-box;
}

a {
    color: #fff;
    text-decoration: none;
    font-family: "Nunito Sans", sans-serif;
    transition: color 0.3s, text-shadow 0.3s;
}

a:hover {
    text-shadow: 0 0 10px currentColor;
}

a:nth-child(1):hover {
    color: #e57373;
}

a:nth-child(2):hover {
    color: #81c784;
}

a:nth-child(3):hover {
    color: #64b5f6;
}

a:nth-child(4):hover {
    color: #ffb74d;
}

a:nth-child(5):hover {
    color: #ba68c8;
}

body {
    margin: 0;
    padding: 0;
    height: 100vh;
    color: white;
    background: black;
    font-family: "Inter", sans-serif;
    text-transform: uppercase;
}

.title {
    font-size: clamp(1rem, 5vw, 5rem);
    font-family: "Playfair Display", serif;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.5em;
    margin-right: -0.5em;
    color: hsl(0, 0, 80%);
    width: 90vw;
    max-width: 1200px;
}

.title:hover {
    transform: scale(1.05);
    text-shadow: 0 0 10px hsl(0, 0, 80%);
}

header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    width: 100%;
    z-index: 3;
    height: 7em;
    font-family: "Inter", sans-serif;
    font-size: clamp(0.66rem, 2vw, 1rem);
    letter-spacing: 0.1em;
}

.section {
    height: 100%;
    width: 100%;
    top: 0;
    position: fixed;
    visibility: hidden;

    .wrapper-outer,
    .wrapper-inner {
        width: 100%;
        height: 100%;
        overflow-y: hidden;
    }

    .background {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        background-size: cover;
        background-position: center;

        h2 {
            z-index: 2;
        }

        .clip-text {
            overflow: hidden;
        }
    }
}

.first .background {
    background-image: $bg-gradient,
        url("https://images.unsplash.com/photo-1695634930904-e20e04b7be24?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2OTg2ODg1Mzh8&ixlib=rb-4.0.3&q=85");
}

.second .background {
    background-image: $bg-gradient,
        url("https://images.unsplash.com/photo-1697369574152-58c8e59e35d4?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2OTg2ODg1OTJ8&ixlib=rb-4.0.3&q=85");
}

.third .background {
    background-image: $bg-gradient,
        url("https://images.unsplash.com/photo-1617438817509-70e91ad264a5?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYxNzU2MDk4Mg&ixlib=rb-1.2.1&q=75&w=1920");
}

.fourth .background {
    background-image: $bg-gradient,
        url("https://images.unsplash.com/photo-1617412327653-c29093585207?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYxNzU2MDgzMQ&ixlib=rb-1.2.1&q=75&w=1920");
}

.fifth .background {
    background-image: $bg-gradient,
        url("https://images.unsplash.com/photo-1617141636403-f511e2d5dc17?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYxODAzMjc4Mw&ixlib=rb-1.2.1&q=75w=1920");
    background-position: 50% 45%;
}

h2 * {
    will-change: transform;
}